.lb_highlight_wrapper {
  min-height: 50vh;
  padding-top: 100px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .lb_highlight_wrapper {
    min-height: 40vh;
    padding-top: 30px;
    margin-bottom: 40px;
  }
}

.lb_highlight_title {
  font-size: 38px;
  margin-bottom: 40px;
  color: coral;
  font-weight: 800;
}

@media only screen and (max-width: 600px) {
  .lb_highlight_title {
    font-size: 18px;
    margin-bottom: 40px;
    color: coral;
    font-weight: 800;
    line-height: 1.5;
  }
}

.lb_highlight_text {
  font-size: 15px;
}
