.lb_jumbo_wrap {
  padding: 50px 10px;
  width: 80%;
  align-items: center;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .lb_jumbo_wrap {
    padding: 50px 10px;
    width: 100%;
    align-items: center;
    margin: auto;
  }
}

.lb_jumbo_title {
  font-size: 44px;
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .lb_jumbo_title {
    font-size: 24px;
    font-weight: 700;
  }
}

.lb_jumbo_text {
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (max-width: 600px) {
  .lb_jumbo_text {
    font-size: 16px;
    line-height: 30px;
  }
}

.lb_jumbo_image {
  border-left: 10px red solid;
  border-bottom: 10px red solid;
  border-width: 90%;
}
