.navbar {
  background: linear-gradient(180deg, #80cbc4 70%, rgba(7, 61, 58, 0.5));
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.myLogo {
  width: 180px;
  padding-top: 20px;
  margin-left: 70px;
}

@media only screen and (max-width: 720px) {
  .myLogo {
    width: 100px;
    padding-top: 10px;
  }
  .navbar {
    background: linear-gradient(
      180deg, #80cbc4 70%, rgba(7, 61, 58, 0.5));;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
}

/* @media only screen and (max-width: 600px) {
  .navbar {
    background: #064757;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 100% !important;
  }
} */

.navbar-logo {
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .navbar-logo {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-left: -30px !important;
    margin-top: -60px !important;
  }
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);

  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 50px;

  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .nav-item {
    display: flex;
    align-items: center;

    margin-bottom: 50px;
    font-weight: bold;
  }
}

.nav-links {
  color: #fff;
  text-decoration: none;
}
@media screen and (max-width: 960px) {
  .nav-links {
    color: white;
    text-decoration: none;
    font-size: 18px;
    /* border-radius: 20px; */
    text-transform: capitalize;
    border: 1px #fff solid;
    margin-bottom: 0px;
    height: 10px !important;
    padding: 0px !important;
  }
}

.fa-bars {
  color: #fff;
  font-size: 20px;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  margin-left: -40px !important;
  margin-top: -30px !important;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 0px;
    left: -120%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-top: 40px;
  }

  .nav-menu.active {
    background: linear-gradient(120deg, #064757 20%, #04afda);
    /* background: #242222; */
    margin-top: -5%;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    min-height: 100vh;
  }

  .nav-links {
    text-align: center;
    padding: 6rem;
    width: 80%;
    display: table;
    margin: auto;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  button {
    display: none;
  }
}
.account {
  font-weight: bold;
  color: #064757;
  background: #fff;
}
@media only screen and (max-width: 720px) {
  .account {
    font-weight: bold;
    color: #fff;
    background: #064757;

    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .myLogo {
    margin-top: 15px;
    margin-left: 10%;
  }
}

nav i,
nav [class^="mdi-"],
nav [class*="mdi-"],
nav i.material-icons {
  display: block;
  font-size: 24px;
  height: 0px !important;
  line-height: 56px;
}
