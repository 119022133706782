.lb_detail_text {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 30px;
}

/* .lb_detail_img {
  height: 250px;
} */
