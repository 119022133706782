.lb_sliderBanner {
  background-image: url(../../assets/Image/bg.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 70vh;
  box-shadow: inset 0 0 0 1000px rgba(7, 61, 58, 0.5);
}

@media only screen and (max-width: 600px) {
  .lb_sliderBanner {
    background-image: url(../../assets/Image/bg.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 50vh;
    box-shadow: inset 0 0 0 1000px rgba(7, 61, 58, 0.5);
  }
}

.lb_slide_content {
  padding-top: 20%;
}

.lb_slide_content h3 {
  font-size: 56px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .lb_slide_content h3 {
    font-size: 36px;
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 20px;
  }
}

.lb_slide_content p {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}
