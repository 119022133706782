.lb_radioXp_wrap {
  padding: 0px 30px;
}

.lb_radioXp_wrap p {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 30px;
}

.lb_radioXp_wrap h4 {
  font-size: 44px;
  font-weight: 700;
}

.lb_jumbo_wrap {
  padding: 50px 10px;
  width: 80%;
  align-items: center;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .lb_radioXp_wrap {
    padding: 0px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .lb_radioXp_wrap h4 {
    font-size: 24px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 600px) {
  .lb_radioXp_wrap p {
    font-size: 16px;
    line-height: 30px;
  }
}

.lb_jumbo_image {
  border-left: 10px red solid;
  border-bottom: 10px red solid;
  border-width: 90%;
}
