.lb_topbanner_wrap {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
  box-shadow: inset 0 0 0 1000px rgba(7, 61, 58, 0.5);
  padding-top: 10%;
}

@media only screen and (max-width: 600px) {
  .lb_topbanner_wrap {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 30vh;
    box-shadow: inset 0 0 0 1000px rgba(7, 61, 58, 0.5);
    padding-top: 10%;
  }
}

/* .lb_topbanner {
} */
