.lb_mission_wrap {
  min-height: 50vh;
  padding-top: 100px;
}

@media only screen and (max-width: 600px) {
  .lb_mission_wrap {
    min-height: 50vh;
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

.lb_mission_title {
  font-size: 38px;
  margin-bottom: 20px;
  color: coral;
  font-weight: 800;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .lb_mission_title {
    font-size: 25px;
    margin-bottom: 20px;
    color: coral;
    font-weight: 500;
    text-align: center;
  }
}
.lb_mission_text {
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .lb_mission_text {
    font-size: 13px;
  }
}

.lb_mission_container {
  width: 90%;
  align-items: center;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .lb_mission_container {
    width: 100%;

    margin: auto;
  }
}
